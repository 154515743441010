@import '../../../variables';

.session-create-modal {
  height: 25rem;
  width: 50rem;
  padding: 4.5rem 2rem 2rem;
  border-radius: 0.3rem;
  background-color: var(--bg-clr_1);
  cursor: initial;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__footer {
    margin-top: 2rem;
  }

}

.session-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__btn {
    margin-left: 2rem;
  }
}
