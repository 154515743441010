@import '../../../variables';

.bg-selector {
  cursor: pointer;
  border-radius: 6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &--default-size {
    @include fixed-size(3.1rem);
  }

  &--shadow {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  }

  &--checked {
    border: 0.1rem solid var(--brbr-clr_5);
    box-shadow: none;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      min-width: 100%;
      min-height: 100%;
      //background-color: rgba(256,256,256,0.4);
      background-image: url('../../../img/check.svg');
      background-size: contain;
    }
  }
}

.selector-wrapper {
  &--offset:not(:last-child) {
    margin-right: 1rem;
  }
}
