@import '../../../variables';

.app-btn {
  @include setFont('Helvetica Neue', 1.6rem, 2.2rem);

  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;

  &--sm {
    //padding: 0.3rem 1.6rem;
    padding: 0 1.6rem;
    min-height: 2.8rem;
  }

  &--md {
    //padding: 0.7rem 1.6rem;
    padding: 0 1.6rem;
    min-height: 3.6rem;
  }

  &--lg {
    //padding: 1.1rem 2rem;
    padding: 0 2rem;
    min-height: 4.4rem;
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &--default {
    color: var(--txt-clr_4);
    background-color: var(--bg-clr_2);
  }

  &--alt {
    color: var(--txt-clr_1);
    background-color: var(--bg-clr_5);
  }

  &--fill-container {
    width: 100%;
    height: 100%;
  }

  &__pic {
    &--offset {
      margin-right: 0.6rem;
    }

    &--default-fill {
      fill: var(--bg-clr_4);
    }

    &--default-stroke {
      stroke: var(--bg-clr_4);
    }

    &--alt-fill {
      fill: var(--bg-clr_1);
    }

    &--alt-stroke {
      path {
        // temporary
        stroke: var(--bg-clr_1);
      }
    }

    &--md {
      @include fixed-size(1.8rem);
    }

    &--sm {
      @include fixed-size(1.6rem);
    }

    &--lg {
      @include fixed-size(2.4rem);
    }
  }

  &__label {
    &--default {
      color: var(--bg-clr_4);
    }

    &--alt {
      color: var(--bg-clr_1);
    }

    &--bold {
      font-weight: bold;
    }
  }

  &[disabled] {
    opacity: 0.8;
  }
}
