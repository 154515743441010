@import '../../../variables';
$card-radius: 1.2rem;

.card-scene {
  perspective: 60rem;
  cursor: pointer;
  transition: opacity 1.3s;
  border-radius: $card-radius;

  &--transparent {
    transition-delay: 0.3s;
    opacity: 0;
  }

  &--abs {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__btn-pic {
    @include fixed-size(2.4rem);
  }

  &__btn {
    $ic-offset: 0.6rem;
    $ic-size: 3.2rem;
    $bx-shdw: rgba(0, 0, 0, 0.15);

    @include fixed-size(3.2rem);

    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--bg-clr_2);
    box-shadow: 0 0.4rem 0.4rem $bx-shdw;
    user-select: none;

    &--disabled {
      display: none;
    }

    &--enabled {
      @extend %centerContent;
    }

    &--left {
      margin-top: $ic-offset;
      margin-left: $ic-offset;
      left: 0;
      top: 0;
    }

    &--bot {
      margin-bottom: $ic-offset;
      margin-left: $ic-offset;
      left: 0;
      bottom: 0;
    }

    &--right {
      margin-right: $ic-offset;
      margin-top: $ic-offset;
      top: 0;
      right: 0;
    }
  }
}

.moveable-control-box.rnd-card-controls.rCSwtyrwf .moveable-rotation {
  height: 2rem;
}

.card {
  $card-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.1);

  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform-origin: top right;
  border-radius: $card-radius;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.06);

  &--flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  &__img {
    $size: 100%;

    border-radius: $card-radius;
    height: $size;
    width: $size;
    position: absolute;
    backface-visibility: hidden;
    background-position: center;
    background-repeat: no-repeat;

    &--back {
      transform: rotateY(180deg);
    }

    &--front {
      background-size: cover;
    }
  }
}
