@import "../../../variables";

.alert-modal {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: $color_1;
    width: 400px;
    height: 200px;
  }
}
