@import '../../../variables';

.radio-btn {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    display: none;
  }

  &__fake {
    @include fixed-size(2.4rem);

    border: 0.1rem solid var(--brbr-clr_3);
    border-radius: 50%;
    background-color: var(--bg-clr_1);

    &--offset {
      margin-right: 1rem;
    }

    &--checked {
      $size: 1.6rem;
      $offset: $size/2;

      position: relative;

      &::after {
        @include fixed-size($size);

        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -$offset;
        margin-top: -$offset;
        background-color: var(--bg-clr_5);
        border-radius: 50%;
      }
    }
  }

  &__label {
    line-height: 2.2rem;
  }
}
