@import '../../../variables';

.input-wrapper {
  width: 100%;

  &__label {
    @include setFont(null, 1.2rem, 1.7rem);

    &--lg-pd {
      padding-bottom: 1rem;
    }

    &--md-pd {
      padding-bottom: 0.8rem;
    }

    &--sm-pd {
      padding-bottom: 0.6rem;
    }
  }

  &__error {
    @include setFont(null, 1.6rem, 2.2rem);

    color: var(--txt-clr_7);
    padding-top: 1rem;
  }
}

.input-container {
  position: relative;

  &__input {
    $icon-pad-r: 3rem;
    @include setFont(null, 1.6rem, 2.2rem);

    border: 0.1rem solid var(--brbr-clr_3);
    border-radius: $input-radius;
    width: 100%;

    &::placeholder {
      color: var(--txt-clr_3);
    }

    &--lg {
      padding: 1rem 2rem;
      min-height: 4.4rem;
    }

    &--md {
      padding: 0.7rem 2rem;
      min-height: 3.6rem;
    }

    &--sm {
      padding: 0.3rem 2rem;
      min-height: 2.8rem;
    }

    &--with-eye {
      padding-right: $icon-pad-r;
    }

    &--with-clear-icon {
      padding-right: $icon-pad-r;
    }
  }

  &__eye-img {
    position: absolute;
    top: 50%;
    margin-top: -1.2rem;
    right: 0.5rem;
  }

  &__clear-icon {
    position: absolute;
    top: 50%;
    margin-top: -1.5rem;
    right: 0.8rem;
    padding: 0.7rem 0 0.7rem 0.7rem;
    box-sizing: initial;
  }
}
