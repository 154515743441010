@import '../../../variables';

.header {
  @extend %centerContent;
  @include fixed-size(100%, 6rem);

  justify-content: space-between;
  padding: 0 2rem;

  &__logo {
    @extend %centerContent;

    font-family: 'Krub', sans-serif;
    font-weight: bold;
    cursor: pointer;
  }

  &__logo-pic {
    @include fixed-size(8.1rem, 3.4rem);

    // margin-right: 0.6rem;
  }

  &__info-block {
    @extend %centerContent;

    margin-left: 8.7rem;
  }

  &__buttons {
    @extend %centerContent;

    margin-left: auto;

    &--hidden {
      visibility: hidden;
    }
  }

  &__buttons-item:not(:last-child) {
    margin-right: 1rem;
  }
}

.session-info-block {
  @include setFont(null, 1.6rem, 2.2rem);

  font-family: 'Helvetica Neue', sans-serif;

  &__item:not(:last-child) {
    margin-right: 2rem;
  }
}
