@import '../../../variables';

.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  &__content {
    padding: 2rem;
    border-radius: 2rem;
    background-color: var(--bg-clr_1);
    width: 40rem;
  }
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__input {
    width: 100%;
    margin-bottom: 3rem;
  }
}

.auth-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__msg {
    text-align: center;
  }
}
