.up-btn {
  position: fixed;
  z-index: 2;
  left: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;

  &.shown {
    display: block;
  }
}