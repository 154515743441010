@import 'variables';
@import 'normalize';
@import './fonts/fonts';

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  //background-color: rgba(0, 0, 0, 0.2);
  font-family: 'Helvetica Neue', sans-serif;
  background: $mainBgGradient;
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: var(--txt-clr_4);

  > div:not(.modal-container) {
    flex-grow: 1;
    width: 100%;
  }
}

.warning-txt {
  color: $warning !important;
}

.modal-dark-overlay {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.moveable-control-box.rnd-card-controls {
  .moveable-line {
    background-color: var(--brbr-clr_6);
  }

  .moveable-control {
    $size: 1rem;

    width: $size;
    height: $size;
    margin-top: -$size/2;
    margin-left: -$size/2;
    border-radius: 0;
    background-color: var(--bg-clr_5);
  }

  &--inactive {
    .moveable-line {
      opacity: 0;
    }
  }

  .moveable-control.moveable-rotation-control {
    $size: 1.5rem;

    border: 0;
    width: $size;
    height: $size;
    margin-top: -1.6rem;
    margin-left: -$size/2;
    background-image: url('./img/rotate-cw.svg');
    background-color: transparent;
    background-position: center;
    background-size: contain;
  }
}

//noinspection ALL
.ReactModal__Content {
  //text colors
  --txt-clr_1: var(--color_1);
  --txt-clr_2: var(--color_2);
  --txt-clr_3: var(--color_3);
  --txt-clr_4: var(--color_4);
  --txt-clr_5: var(--color_5);
  --txt-clr_6: var(--color_6);
  --txt-clr_7: var(--color_7);
  //background colors (use for stroke and fill of svg)
  --bg-clr_1: var(--color_1);
  --bg-clr_2: var(--color_2);
  --bg-clr_3: var(--color_3);
  --bg-clr_4: var(--color_4);
  --bg-clr_5: var(--color_5);
  //border colors
  --brbr-clr_1: var(--color_1);
  --brbr-clr_2: var(--color_2);
  --brbr-clr_3: var(--color_3);
  --brbr-clr_4: var(--color_4);
  --brbr-clr_5: var(--color_5);
  --brbr-clr_6: var(--color_6);

  outline: none;
}

@import 'components/modals/sessionCreateResult/index';
@import 'components/modals/cardPreview/index';
@import 'components/modals/alertModal/index';
@import 'components/mainPages/createSession/index';
@import 'components/mainPages/sessionRoom/index';
@import 'components/mainPages/appError/index';
@import 'components/mainPages/noMatch/index';
@import 'components/mainPages/header/index';
@import 'components/mainPages/home/index';
@import 'components/mainPages/auth/index';
@import 'components/helpers/copyField/index';
@import 'components/helpers/checkBox/index';
@import 'components/helpers/tooltip/index';
@import 'components/helpers/button/index';
@import 'components/helpers/input/index';
@import 'components/helpers/i18n/index';
@import 'components/helpers/card/index';
@import 'components/helpers/btnUp/index';
@import 'components/helpers/sidePanel/index';
@import 'components/helpers/radioButton/index';
@import 'components/helpers/bgSelector/index';
@import 'components/helpers/dropdown/index';
@import 'components/mainPages/footer/index';
