@import "../../../variables";

.no-match-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(2.86deg, #8AC53F 2.14%, #0AB494 97.62%);
  color: white;

  h1 {

  }

  p {

  }
}