@import '../../../variables';

$copy-field-Bg: $color_1;

.copy-field-container {
  @include setFont(null, 1.2rem, 1.7rem, bold);

  display: inline-block;
  position: relative;
  overflow: hidden;
  color: var(--txt-clr_5);

  &__value {
    position: absolute;
    z-index: 1;
    left: -1rem;
    max-width: 1rem;
    max-height: 1rem;
  }

  &__trigger {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 0.3rem;
    //padding: 0.5rem;
    height: 100%;
    //background-color: $copy-field-Bg;
  }


  &__done {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--txt-clr_1);
    border-radius: 0.3rem;
    transform: translateY(100%);

    &--shown {
      transform: translateY(0);
    }
  }

}
