@import '../../../variables';

.home-page {
  &__container {
    display: flex;
    flex-direction: column;
    background: var(--bg-clr_1);
    border-radius: 2rem;
    padding: 2rem 4rem 6rem 3rem;
    margin: 2rem 2rem 5rem;
    height: 100%;
    min-width: 110rem;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__title {
    @include setFont(null, 3.6rem, 5rem, bold);

    margin: 0;
  }

  &__fields {
    @extend %centerTxt;
  }

  &__input-item {
    margin-right: 1rem;
    min-width: 27.4rem;
  }

  &__sort-item {
    min-width: 12.6rem;
  }
}

.sessions-table {
  @include setFont(null, 1.6rem, 2.2rem);

  width: 100%;
  border-collapse: collapse;
  // border-spacing: initial;
  border: 0.1rem solid var(--brbr-clr_3);

  &__header {
    background: var(--bg-clr_2);
  }

  &__col {
    text-align: left;
    font-weight: 400;
    border: 0.1rem solid var(--brbr-clr_3);
    height: 4.4rem;
    padding: 0.5rem 0.8rem;
    word-break: break-word;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      min-width: 10rem;
      max-width: 10rem;
      width: 10rem;
    }

    &:nth-child(5) {
      width: 20rem;

    }

    &:nth-child(6) {
      width: 20rem;
    }

    &:nth-child(7) {
      width: 32rem;
    }

    &:nth-child(8) {
      width: 20rem;
    }
  }

  &__col-pic {
    margin-left: auto;
    transform: rotate(90deg);
    cursor: pointer;
    vertical-align: bottom;
  }

  &__row {
    border: 0.1rem solid var(--brbr-clr_3);
    height: 4.4rem;
    padding: 0.5rem 0.8rem;
  }
}

.tools-link {
  @extend %centerTxt;

  max-width: 26rem;
  justify-content: space-between;
  margin: 0 auto;

  &__item {
    @extend %centerTxt;
    @include setFont(null, 1.2rem, 1.7rem, bold);

    color: var(--txt-clr_5);
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 2rem; //temporary
    }
  }
}

.description-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__txt {
    max-width: 26rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--empty-row {
      // NO DISCRIPTION
      color: var(--txt-clr_4);
      opacity: 0.2;
    }
  }
}
