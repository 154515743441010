//-------colors-------
$warning: #ea0b1a;
$easy-green: #5cb85c;
$color_1: #fff;
$app-pad: 20px;
$heightInput: 40px;
$input-radius: 0.4rem;
$inputDefaultBorder: #a08370;
$mobileScreen: 767px;
$mainBgGradient: linear-gradient(180deg, #fff 0%, #f9f4e2 15.1%, #f2ddc0 84.37%, #f1d8b8 100%);

:root {
  --color_1: #fff; //white
  --color_2: #f9f1e4; //cream
  --color_3: #d9d9d9; //gray
  --color_4: #333; //black
  --color_5: #ea9a39; //accent
  --color_6: #c4c4c4; //gray dark
  --color_7: #ff6d6d; //red
}

.default-theme {
  //text colors
  --txt-clr_1: var(--color_1);
  --txt-clr_2: var(--color_2);
  --txt-clr_3: var(--color_3);
  --txt-clr_4: var(--color_4);
  --txt-clr_5: var(--color_5);
  --txt-clr_6: var(--color_6);
  --txt-clr_7: var(--color_7);
  //background colors (use for stroke and fill of svg)
  --bg-clr_1: var(--color_1);
  --bg-clr_2: var(--color_2);
  --bg-clr_3: var(--color_3);
  --bg-clr_4: var(--color_4);
  --bg-clr_5: var(--color_5);
  //border colors
  --brbr-clr_1: var(--color_1);
  --brbr-clr_2: var(--color_2);
  --brbr-clr_3: var(--color_3);
  --brbr-clr_4: var(--color_4);
  --brbr-clr_5: var(--color_5);
  --brbr-clr_6: var(--color_6);
}

%centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

%centerTxt {
  display: flex;
  align-items: center;
}

@mixin setFont($family: null, $size: 1.6rem, $lineHeight: normal, $fontWeight: null) {
  @if ($family) {
    font-family: $family, sans-serif;
  }

  @if ($fontWeight) {
    font-weight: $fontWeight;
  }

  font-size: $size;
  line-height: $lineHeight;

}

@mixin fixed-size($width, $height: null) {
  width: $width;
  max-width: $width;
  min-width: $width;

  @if ($height == null) {
    height: $width;
    min-height: $width;
    max-height: $width;
  } @else {
    height: $height;
    min-height: $height;
    max-height: $height;
  }
}
