@import '../../../variables';

.select-wrapper {
  width: 100%;

  &__label {
    @include setFont(null, 1.2rem, 1.7rem);

    &--lg-pd {
      padding-bottom: 1rem;
    }

    &--md-pd {
      padding-bottom: 0.8rem;
    }

    &--sm-pd {
      padding-bottom: 0.6rem;
    }
  }
}

// rewrite dropdown styles
.select-container {
  @include setFont(null, 1.6rem, 2.2rem);

  .select__control {
    background-color: var(--bg-clr_2);
    border: initial;
    height: 4.4rem;
    cursor: pointer;

    &--is-focused {
      box-shadow: initial;
    }
  }

  .select__placeholder {
    @include setFont(null, 1.6rem, 2.2rem);

    color: var(--txt-clr_4);
  }

  .select__indicators .select__indicator-separator {
    display: none;
  }

  .select__value-container {
    @include setFont(null, 1.6rem, 2.2rem);

    padding: 1.1rem 2rem;
    color: var(--txt-clr_4);
  }

  .select__menu {
    border: 0.1rem solid var(--bg-clr_2);
    box-shadow: initial;
    border-radius: $input-radius;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .select__menu-list .select__option {
    cursor: pointer;
    background-color: transparent;
    color: var(--txt-clr_4);
    transition: color 300ms;
    padding: 1rem 0.5rem 1rem 2rem;

    &--is-focused {
      color: var(--txt-clr_5);
      transition: color 300ms;
    }
  }
}
