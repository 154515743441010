@import '../../../variables';

.session-field {
  padding: 2rem;
  display: flex;
}

.field-session {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 2rem;

  &__panel-btns {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1.6rem;
    top: 1.3rem;
  }

  &__panel-btns-item {
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.9rem;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.btns-panel {
  @extend %centerTxt;

  flex-wrap: wrap;

  &__item {
    margin-bottom: 1rem;
  }

  &__item:not(:last-child) {
    margin-right: 1rem;
  }
}

//rewrite tab bar styles here
.card-tabs {
  height: 100%;
  padding-bottom: 2.5rem;
  padding-top: 3rem;

  .rc-tabs {
    height: 100%;
    border: initial;

    &-nav {
      &::after {
        content: '';
        position: absolute;
        background-color: var(--bg-clr_2);
        height: 0.3rem;
        width: 93%;
        bottom: 1.3rem;
        left: 2.5rem;
        z-index: -1;
      }
    }

    &-content-holder {
      overflow-y: scroll;
    }

    &-tab-btn {
      @include setFont(null, 1.6rem, 2.2rem);

      padding-bottom: 0.3rem;

      &:focus {
        outline: initial;
      }
    }

    &-ink-bar {
      background: var(--brbr-clr_5) !important;
      height: 0.3rem;
    }

    &-content-holder:focus,
    &-content:focus,
    &-tabpane:focus {
      outline: initial;
    }

    &-nav-wrap {
      margin-bottom: 1.3rem;
    }

    &-tab {
      padding-left: 0;
      padding-right: 0;
      color: var(--txt-clr_4);
      margin-right: 2rem;
      background: initial;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: initial;
      }

      &-active {
        border: initial;
        font-weight: bold;
      }
    }

    &-extra-content {
      cursor: pointer;
    }
  }

  .rc-tabs-nav-measure-ping-left::before,
  .rc-tabs-nav-wrap-ping-left::before {
    left: 0;
    border-left: 1px solid transparent;
  }

  .rc-tabs-nav-measure-ping-right::after,
  .rc-tabs-nav-wrap-ping-right::after {
    right: 0;
    border-right: 1px solid transparent;
  }

  .rc-tabs-nav-more {
    border: initial;
    background: initial;
  }

  &__btns-wrapper {
    margin-bottom: 1.2rem;
  }
}

.arrow {
  @include fixed-size(2.5rem);

  cursor: pointer;
  height: 100%;

  &__pic {
    @include fixed-size(2.5rem);

    path {
      stroke: var(--brbr-clr_4);
    }
  }

  &__right {
    @include fixed-size(2.5rem);
  }

  &__left {
    @include fixed-size(2.5rem);

    transform: rotate(180deg);
  }
}

.cards-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:focus {
    outline: initial;
  }

  &__item {
    margin-bottom: 1rem;
  }
}

.session-settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &__item {
    margin-bottom: 5rem;

    &:first-child {
      margin-top: 2.6rem;
    }
  }

  &__bg-selector {
    @extend %centerTxt;

    margin-left: 0.2rem;
  }

  &__item-label {
    @include setFont(null, 1.2rem, 1.7rem);

    &--offset {
      padding-bottom: 2rem;
    }
  }

  &__item-btns {
    @extend %centerTxt;
  }

  &__item-btn:not(:last-child) {
    margin-right: 2rem;
  }

  &__btn-subtitle {
    @include setFont(null, 1.2rem, 1.7rem);

    margin-left: 1.8rem;
  }

}

.checkbox-list {
  display: flex;
  flex-wrap: wrap;

  &__item:not(:last-child) {
    margin-right: 2rem;
  }
}

.uploader-block {
  @extend %centerTxt;

  justify-content: space-between;
  flex-wrap: wrap;

  &__item:not(:first-child) {
    margin-top: 2rem;
  }
}

.bg-uploader {
  display: flex;
  align-items: flex-start;

  &__img {
    background-repeat: no-repeat;
    background-size: cover;

    &--default-size {
      @include fixed-size(8rem, 4rem);
    }

    &--offset {
      margin-right: 2rem;
    }
  }
}

.uploader-actions {
  &__label {
    @include setFont(null, 1.2rem, 1.7rem);

    padding-bottom: 0.6rem;
  }

  &__btns {
    @extend %centerTxt;
  }

  &__btn:not(:last-child) {
    margin-right: 1.6rem;
  }
}

.bg-btn {
  @include setFont(null, 1.2rem, 1.7rem);

  background-color: transparent;
  color: var(--txt-clr_5);
  cursor: pointer;

  &--disabled {
    color: initial;
    opacity: 0.2;
  }
}

.settings-edit-form {
  padding-right: 2rem;
  padding-bottom: 2rem;
  border-top: 0.2rem solid var(--brbr-clr_4);

  &__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
  }

  &__save {
    display: flex;
    flex-direction: column;

    &-msg {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
}
