.card-preview-modal {
  width: 100%;
  height: 100%;
  cursor: initial;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  &.top-align {
    padding-top: 100px;
    align-items: flex-start;
  }

  > div {
    position: relative;
  }

  img {
    user-select: none;
    border-radius: 25px;
  }

  .icon-close {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);

    path {
      fill: white;
    }
  }
}