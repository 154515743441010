@import '../../../variables';

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__input {
    display: none;
  }

  &__fake {
    border-radius: 0.4rem;
    border: 0.1rem solid var(--brbr-clr_3);
    background-color: var(--bg-clr_1);

    &--default-size {
      @include fixed-size(2.4rem);
    }

    &--offset {
      margin-right: 1rem;
    }

    &--checked {
      position: relative;

      &::after {
        @include fixed-size(100%);

        content: '';
        position: absolute;
        background-image: url('../../../img/check.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &__label {
    line-height: 2.2rem;
  }
}
