@import '../../../variables';

.form-container {
  @extend %centerContent;

  flex-direction: column;
  width: 100%;
  max-width: 60rem;

  &__back-link {
    @extend %centerTxt;
    @include setFont(null, 1.6rem, 2.2rem, bold);

    width: 100%;
    cursor: pointer;
    margin-bottom: 2rem;
  }

  &__img {
    transform: rotate(180deg);

    path {
      stroke: var(--brbr-clr_4);
    }
  }

  &__create-form {
    margin-bottom: 5rem;
  }
}

.create-form {
  background-color: var(--bg-clr_1);
  border-radius: 2rem;
  padding: 4rem 6rem;
  width: 100%;

  &__header {
    @include setFont(null, 3.6rem, 5rem, bold);

    margin-top: 0;
    margin-bottom: 3.5rem;
  }

  &__row-item {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 3.5rem;
  }

  &__row-item-selected {
    @include setFont(null, 1.6rem, 2.2rem);

    overflow: hidden;
    text-overflow: ellipsis;
    width: 34.9rem;
    min-width: 100%;
    min-height: 4.4rem;
    padding: 1.1rem 2rem;
    background-color: var(--bg-clr_2);
  }

  &__btn-wrapper {
    margin-left: 1.1rem;
    height: 44px;
    min-width: 12rem;
    max-width: 12rem;
  }

  &__options-title {
    @extend %centerTxt;
    @include setFont(null, 1.6rem, 2.2rem, bold);

    color: var(--txt-clr_5);
    margin-bottom: 3.2rem;
    cursor: pointer;
  }

  &__options-arrow {
    transition: transform 0.1s ease-in-out;
    margin-left: 0.6rem;

    &--open {
      transform: rotate(90deg);
      transition: transform 0.1s ease-in-out;
    }
  }
}

.session-other-options {
  &__option {
    margin-bottom: 3rem;
  }

  &__option-label {
    @include setFont(null, 1.2rem, 1.7rem);

    padding-bottom: 2rem;
  }

  &__container-radio {
    @extend %centerTxt;

    margin-bottom: 3rem;
  }

  &__radio:not(:last-child) {
    margin-right: 2rem;
  }

  &__bg-upload {
    @extend %centerTxt;

    &-btn {
      min-width: 21.8rem;
    }
  }
}
