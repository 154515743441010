.tooltip {
  &-trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 0.8rem;
    background: #5cb8b09c;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 400;

    &:hover {

      .tooltip-txt {
        display: flex;
        }
    }
  }

  &-txt {
    display: none;
    position: absolute;
    z-index: 2;
    top: -4px;
    word-break: break-word;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 5px 3px 5px 7px;
    border-radius: 12px;
    margin-top: 15px;
    font-family: Arial;
    font-size: 0.95rem;
    border: 1px solid #a59a9a;
    color: #000000;
    min-height: 30px;
    max-width: 300px;
    min-width: 250px;
    background-color: #FFFFFF;

    &-left {
      left: 25px;
    }

    &-right {
      right: 25px;
    }
  }
}