.footer-main {
  min-height: 5rem;
  max-height: 5rem;
}

.footer-links {
  @extend %centerContent;
  @include setFont(null,1.2rem,1.7rem);

  height: 100%;

  &__item:not(:last-child) {
    margin-right: 2.4rem;
  }
}
