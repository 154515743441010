.side-panel {
  position: absolute;
  right: -69rem;
  top: 0;
  width: 69rem;
  height: 100%;
  max-width: calc(100vw - 4rem);
  word-break: break-word;
  background-color: $color_1;
  border-radius: 2rem;
  transition: transform 0.3s linear;
  padding: 2.5rem;

  &--active {
    transform: translateX(-69rem);
    box-shadow: -10px 4px 14px rgba(0, 0, 0, 0.15);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .panel-header-item {
      display: flex;
      align-items: center;

      &.clickable {
        cursor: pointer;
      }

      .item-label {
        @include setFont(null, 1.2rem, 1.7rem);

        margin-left: 0.9rem;

        &--lg {
          @include setFont(null, 1.6rem, 2.2rem);
        }
      }
    }
  }
}
